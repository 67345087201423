import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7fe56e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-basis f_column" }
const _hoisted_2 = { class: "flex1" }

import { useRoute } from 'vue-router';

export default {
  setup(__props) {

const route = useRoute()

return (_ctx, _cache) => {
  const _component_el_header = _resolveComponent("el-header")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_el_header, { class: "title" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_unref(route).meta.title), 1)
      ]),
      _: 1
    }),
    _createElementVNode("main", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

}